const ACTIVITY_TYPES = [
  {
    key: 'cybersecurity',
    label: 'Cyber Security',
    icon: 'newspaper',
  },
  {
    key: 'esg',
    label: 'ESG',
    icon: 'newspaper',
  },
  {
    key: 'ma',
    label: 'Mergers & Acquisitions',
    icon: 'newspaper',
  },
  {
    key: 'negative_financials',
    label: 'Negative Financials',
    icon: 'newspaper',
  },
  {
    key: 'negative_sentiment',
    label: 'Negative Sentiment',
    icon: 'newspaper',
  },
  {
    key: 'regulatory',
    label: 'Regulatory',
    icon: 'newspaper',
  },
];

export default ACTIVITY_TYPES;
