// ambee api
import loadAmbeeAqiApi from '../../../ambee/api/loadAqi.api.ambee';
import loadAmbeeFireApi from '../../../ambee/api/loadFire.api.ambee';
import loadAmbeeNaturalDisasterApi from '../../../ambee/api/loadNaturalDisaster.api.ambee';
import loadAmbeeWeatherApi from '../../../ambee/api/loadWeather.api.ambee';

// company redux actions
import {setGeneralCompanyData as setGeneralCompanyDataAction} from '../../../company/redux/reducer.redux.company';

// data services
import loadAndAttachAsyncJsonToObjectService from '../../../data/services/loadAndAttachAsyncJsonToObject.service.data';

// layout components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// propType
import PropTypes from 'prop-types';

// react
import React, {Component} from 'react';

// react redux
import {connect} from 'react-redux';

// weather components
import WeatherLayout from '../../components/WeatherLayout/WeatherLayout';

class CompanyWeatherContainer extends Component {
  static propTypes = {
    company: PropTypes.object,
    dispatch: PropTypes.func,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const {company, dispatch} = this.props;

    if (!!company?.weather || company?.weatherLoaded)
      return this.setState({loading: false});

    try {
      const loadedData = await loadAndAttachAsyncJsonToObjectService({
        company: {},
        dataList: {
          aqi: loadAmbeeAqiApi(),
          fire: loadAmbeeFireApi(),
          naturalDisaster: loadAmbeeNaturalDisasterApi(),
          weather: loadAmbeeWeatherApi(),
        },
      });

      const combinedCompany = {
        ...company,
        weather: {
          ...loadedData,
        },
        weatherLoaded: true,
      };

      dispatch(
        setGeneralCompanyDataAction({loading: false, company: combinedCompany})
      );
      this.setState({loading: false});
    } catch (error) {
      this.setState({loading: false});
    }
  };

  render() {
    const {loading} = this.state;
    return loading ? <FullScreenLoader /> : <WeatherLayout />;
  }
}

export default connect((state) => ({company: state.company.company}))(
  CompanyWeatherContainer
);
