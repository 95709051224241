// cyberSecurity lib
import generateSupplierCyberSecurityData from '../../cyberSecurity/lib/generateSupplierCyberSecurityData.lib.cyberSecurity';

// data lib
import prepareData from '../../data/lib/prepare.lib.data';

// financial lib
import mapFinancials from '../../financial/lib/mapFinancials.lib.financial';

const prepareSupplierDashboardData = ({
  alerts = [],
  company,
  tenantData = {},
}) => {
  const preparedOriginalHaloScores = prepareData({
    scores: company.HaloScores,
  });
  const preparedWeightedHaloScores = prepareData({
    scores: company.weightedHaloScores,
  });

  const companyTenantData = tenantData?.[company.CompanyId] || {};

  const haloAlerts = [...alerts].filter(
    ({supplierId, workflow}) =>
      workflow.actions.includes('alert') &&
      `${supplierId}` === `${company.CompanyId}`
  );

  const combinedAlerts = [...haloAlerts];

  const supplier = {
    ...companyTenantData,
    ...company,
    alerts: combinedAlerts,
    CyberSecurity: generateSupplierCyberSecurityData({
      supplier: company,
      tenantData: companyTenantData,
    }),
    HaloScores: preparedWeightedHaloScores.haloScores,
    historicHaloScores: preparedWeightedHaloScores.historicHaloScores,
    originalHaloScores: {
      HaloScores: preparedOriginalHaloScores.haloScores,
      historicHaloScores: preparedOriginalHaloScores.historicHaloScores,
    },
  };

  // TODO: Only for Zillow sync on 2/14/2024. Remove after sync.
  if (
    ['91057'].includes(`${supplier.CompanyId}`) &&
    !!supplier?.originalHaloScores?.HaloScores?.Financial_Financial?.Value
  ) {
    supplier.originalHaloScores.HaloScores.Financial_Financial.Value = 77;
  }

  if (!!supplier.financials) {
    supplier.financials = {
      ...supplier.financials,
      ...mapFinancials(supplier),
    };
  }

  return supplier;
};

export default prepareSupplierDashboardData;
