// ant components
import {Col, Row, Select} from 'antd';

// date formatters
import formatISODate from '../../../date/formatters/formatISO.formatter.date';

// layout components
import StatementBlock from '../../../layout/components/StatementBlock/StatementBlock';

// local components
import Activities from './components/Activities';
import Activity from './components/Activity';
import ActivityContent from './components/ActivityContent';
import ActivityImage from './components/ActivityImage';
import ActivityImageContainer from './components/ActivityImageContainer';
import Container from './components/Container';
import Date from './components/Date';

// local constants
import ACTIVITY_TYPES from './constants/activityTypes.constant';

// local lib
// import getColumns from './lib/getColumns.lib';

// react
import React, {useState} from 'react';

// redux
import {useSelector} from 'react-redux';

const MeltwaterNewsList = () => {
  const {company} = useSelector((state) => ({
    company: state.company.company,
  }));
  const [display, setDisplay] = useState('all');
  const [sentiment, setSentiment] = useState('all');

  const availableArticleTypes = [...ACTIVITY_TYPES].reduce(
    (combined, articleType) => {
      const articles = [
        ...(company?.meltwaterNews?.articles?.[articleType.key]?.documents ||
          []),
      ];
      return [...combined, {...articleType, articles}];
    },
    []
  );

  return (
    <Container>
      <Row gutter={[20, 20]}>
        {availableArticleTypes.length > 1 && (
          <Col className="hide-on-print">
            <Select
              value={display}
              style={{width: '260px'}}
              onChange={(value) => setDisplay(value)}
              options={[
                {
                  value: 'all',
                  label: (
                    <div>
                      <i className="mdi mdi-view-list-outline" /> All Articles
                    </div>
                  ),
                },
                ...[...availableArticleTypes].map((articleType) => ({
                  value: articleType.key,
                  label: (
                    <div>
                      <i className={`mdi mdi-${articleType.icon}`} />{' '}
                      {articleType.label}
                    </div>
                  ),
                })),
              ]}
            />
          </Col>
        )}
        <Col className="hide-on-print">
          <Select
            value={sentiment}
            style={{width: '260px'}}
            onChange={(value) => setSentiment(value)}
            options={[
              {
                value: 'all',
                label: 'All Sentiments',
              },
              {
                value: 'negative',
                label: 'Negative',
              },
              {
                value: 'positive',
                label: 'Positive',
              },
              {
                value: 'neutral',
                label: 'Neutral',
              },
            ]}
          />
        </Col>
        {availableArticleTypes.map((articleType) => {
          const displayArticles =
            display === 'all' || display === articleType.key;
          const articles = !displayArticles
            ? []
            : sentiment === 'all'
            ? [...articleType.articles]
            : [...articleType.articles].filter(
                (article) => article?.enrichments?.sentiment === sentiment
              );
          return (
            displayArticles && (
              <Col key={articleType.key} span={24}>
                <StatementBlock
                  title={
                    <div>
                      <i className={`mdi mdi-${articleType.icon}`} />{' '}
                      {articleType.label}
                    </div>
                  }
                >
                  <Activities>
                    {!articles.length && (
                      <Activity>
                        <div>No Recent {articleType.label}</div>
                      </Activity>
                    )}
                    {articles.map((article) => (
                      <Activity
                        key={article.id}
                        href={article.url}
                        target="_blank"
                      >
                        <ActivityImageContainer>
                          {!!article?.content?.image && (
                            <ActivityImage src={article?.content?.image} />
                          )}
                        </ActivityImageContainer>
                        <ActivityContent>
                          <div>{article?.content?.title || '-'}</div>
                          {!!article?.published_date && (
                            <Date>
                              {formatISODate({
                                date: article?.published_date,
                                dateFormat: 'MM/dd/yyyy hh:mm a',
                              })}
                            </Date>
                          )}
                        </ActivityContent>
                      </Activity>
                    ))}
                  </Activities>
                </StatementBlock>
              </Col>
            )
          );
        })}
      </Row>
    </Container>
  );
};

export default MeltwaterNewsList;
